import React from 'react';
import { TalksListItem } from '../styles/talksStyles';
import { SubHeading } from '../styles/globalStyles';

const TalksItem = ({ talks }) =>
  talks.map(({ node: { title, event } }, index) => (
    <TalksListItem key={index}>
      <p>
        {index + 1}
        {'. '} {title}
      </p>
      <SubHeading>{event || 'Company Internal'}</SubHeading>
    </TalksListItem>
  ));

export default TalksItem;
